import { useViewportState } from '../providers/ViewportProvider/ViewportContext';
export const useMediaQuery = (queryValueTuple, defaultValue) => {
  const viewportStates = useViewportState();
  const getValue = () => {
    const tupleValue = queryValueTuple.find(tuple => {
      const [viewport] = tuple;
      return viewportStates[viewport];
    });
    return tupleValue ? tupleValue[1] : defaultValue;
  };
  return getValue();
};